.table th,
.table td {
  border: none;
  padding: 24px;
  text-wrap: wrap;
}
.table tr {
  border-bottom: 1px solid #e2e8f0;
}

.table th {
  font-weight: bold;
}
