.drop-area {
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.drop-upload {
    border: 2px dashed;
    border-radius: 8px;
}